export const buttonNameCategory = (categoryName: string) => {
  return categoryName === 'specialMachinery'
    ? 'спецтехнику'
    : categoryName === 'equipment'
    ? 'оборудование'
    : categoryName === 'workService'
    ? 'работы у услуги'
    : categoryName === 'buildingMaterial'
    ? 'стройматериалы'
    : categoryName === 'product'
    ? 'изделия'
    : categoryName === 'realty'
    ? 'недвижимость'
    : '';
};
